import {
  Box,
  ChevronDownIcon,
  DropdownIcon,
  Flex,
  UserMenuItem,
  UserMenuProps,
  useOnClickOutside,
} from 'packages/uikit'
import React, { useEffect, useState } from 'react'
import { usePopper } from 'react-popper'
import styled from 'styled-components'

export const StyledUserMenu = styled(Flex)`
  align-items: center;
  background: transparent;
  border-radius: 8px;

  cursor: pointer;
  display: inline-flex;
  position: relative;

  &:hover {
    opacity: 0.65;
  }
`

export const LabelText = styled.div`
  color: white;
  display: none;
  font-size: 18px;
  white-space: nowrap;

  ${({ theme }) => theme.mediaQueries.md} {
    display: block;
    margin-left: 8px;
    margin-right: 4px;
  }
`

const Menu = styled.div<{ isOpen: boolean }>`
  pointer-events: auto;
  width: 180px;
  visibility: visible;
  z-index: 1001;
  width: 280px;
  position: relative;

  box-shadow: 4px 4px 17px 0px rgba(0, 0, 0, 0.49);
  border: 1px solid rgba(67, 67, 67, 0.5);
  border-radius: 20px;
  background: rgba(0, 0, 0, 1);

  &:before {
    content: '';
    height: 25px;

    position: absolute;
    top: -25px;
    left: 0;
    right: 0;
  }

  ${({ isOpen }) =>
    !isOpen &&
    `
    pointer-events: none;
    visibility: hidden;
  `}

  ${UserMenuItem}:first-child {
    border-radius: 8px 8px 0 0;
  }

  ${UserMenuItem}:last-child {
    border-radius: 0 0 8px 8px;
  }
`

const Wrapper: React.FC<UserMenuProps> = ({ account, text, avatarSrc, variant = 'default', children, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [targetRef, setTargetRef] = useState<HTMLDivElement | null>(null)
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null)
  const accountEllipsis = account ? `${account.substring(0, 2)}...${account.substring(account.length - 4)}` : null
  const { styles, attributes, update } = usePopper(targetRef, tooltipRef, {
    strategy: 'absolute',
    placement: 'bottom-end',
    modifiers: [{ name: 'offset', options: { offset: [0, 10] } }],
  })

  const handleToggle = () => {
    if (update) update()
    setIsOpen((s) => !s)
  }

  useOnClickOutside({ current: targetRef }, () => setIsOpen(false))

  // useEffect(() => {
  //   const showDropdownMenu = () => {
  //     if (update) update()
  //     setIsOpen(true)
  //   }

  //   const hideDropdownMenu = (evt: MouseEvent | TouchEvent) => {
  //     const target = evt.target as Node
  //     if (target && !tooltipRef?.contains(target)) {
  //       setIsOpen(false)
  //       evt.stopPropagation()
  //     }
  //   }

  //   targetRef?.addEventListener('mouseenter', showDropdownMenu)
  //   targetRef?.addEventListener('mouseleave', hideDropdownMenu)

  //   return () => {
  //     targetRef?.removeEventListener('mouseenter', showDropdownMenu)
  //     targetRef?.removeEventListener('mouseleave', hideDropdownMenu)
  //   }
  // }, [targetRef, tooltipRef, setIsOpen, update])

  return (
    <Flex alignItems="center" height="100%" ref={setTargetRef} {...props}>
      <StyledUserMenu onClick={handleToggle}>
        <Box width={['30px', , , , '35px']} height={['30px', , , , '35px']}>
          <img src="/images/symbol/ink.png" alt="" style={{ width: '100%' }} />
        </Box>
        <LabelText title={text || account}>{text || accountEllipsis}</LabelText>
        <Box display={['block', , , , 'none']} ml="8px">
          <DropdownIcon color="transparent" width="10px" />
        </Box>
      </StyledUserMenu>
      <Menu style={styles.popper} ref={setTooltipRef} {...attributes.popper} isOpen={isOpen}>
        <Box>{children?.({ isOpen })}</Box>
      </Menu>
    </Flex>
  )
}

export default Wrapper
